import { List } from 'immutable'
import { DeliveryTimeWindowIdType } from '../../domain/deliveryTimeWindow'
import { DangerousGoods } from '../../types/coreEntitiesTypes'
import { ImmutableMap, ImmutableMapFactory } from '../../types/immutableTypes'
import { TransportLegType } from '../../utils/alystraUtils'
import { GroupRecipientProps } from './MultistopGroupForm'

export type PresetDimension =
  | 'custom'
  | 'letter'
  | 'bag'
  | 'smallbox'
  | 'mediumbox'
  | 'largebox'
  | 'tube'
  | 'quarterpallet'
  | 'halfpallet'
  | 'fullpallet'
  | 'suitcase'
  | 'movingbox'
  | 'trolley'
  | 'custom'
  | 'fullpallet'
  | 'parcel'

export type ISizeProps = {
  id: number
  name: string
  weight: string
  length: string
  width: string
  height: string
  presetDimension: PresetDimension
}

export interface ISize extends ImmutableMap<ISizeProps> {}

export type AddressProps = {
  id?: number
  name: string
  streetname?: string
  address: string
  zipCode: string
  zipArea: any
  countryCode?: any
  country: any
  phone: any
  contactPerson?: string
  instructions?: string
  customerId?: number
  latLng?: ImmutableMap<LatLng> & LatLng
}

export interface LatLng {
  lat: number
  lng: number
}

export interface RecipientProps extends AddressProps {
  customerRef: string
}

export interface IAddress extends ImmutableMap<AddressProps> {}

export interface ICustomer
  extends ImmutableMap<{
    id: number
    name: string
    alystraId: string
    alystraSubcustomer?: string
    creditBlocked: boolean
    addressName: string
    addressZipCode: string
    addressZipArea: string
    addressAddress: string
    addressCountryCode: string
    addressCountry: string
    addressCustomerRef: string
    addressPhone: string
    addressContactPerson?: string
    addressInstructions?: string
    addressLat?: number
    addressLng?: number
    addressId: number | undefined
    information: string | undefined
    address: string
    zipCode: string
    zipArea: string
    countryCode: string
    defaultSenderAddressId: number
    scanToCreatePackages: boolean
  }> {}

export interface ICustomerEventEmail
  extends ImmutableMap<{
    id: number
    customerId: number
    emailId: string
    createdEvent: boolean
    collectedEvent: boolean
    deliveredEvent: boolean
    userAccessLevel: string
  }> {}

export interface IOptimusRouting extends ImmutableMap<OptimusRoutingProps> {}

export interface OptimusRoutingProps {
  zone: string
  operation: string
  articleGroup: string
  alystraArticleCode: string
}

export type PriceRole = 'customerPrice' | 'resourcePrice'

export interface IPriceExplanation
  extends ImmutableMap<{
    article: string
    transportLegIndex?: number
    customerExplanations: List<INamedPriceListExplanation>
    resourceExplanations: List<INamedPriceListExplanation>
  }> {}
export interface INamedPriceListExplanation
  extends ImmutableMap<{
    priceList: string
    explanations: List<string>
  }> {}

export interface IAlystraPrice
  extends ImmutableMap<{
    grossCustomerPrice: string
    grossCustomerVAT: string
    grossResourcePrice: string
    grossResourceVAT: string
    netCustomerPrice: string
    netCustomerVAT: string
    netResourcePrice: string
    netResourceVAT: string
  }> {}

export interface IPricedArticle
  extends ImmutableMap<{
    index: number
    articleCode: string
    articleLabel: string
    customerPrice: number
    resourcePrice: number
    orderId: number
    zeroCustomerPrice: boolean
    zeroResourcePrice: boolean
    transportLegIndex?: number
    transportLegType?: TransportLegType
  }> {}

export interface IOrderFormProps {
  pickupName: string
  pickupAddress: string
  pickupZipArea: string
  pickupZipCode: string
  pickupAddressLat: number
  pickupAddressLng: number
  pickupCountryCode: string
  pickupPhone: string
  pickupEmail?: string | null
  pickupSecondPhone?: string | null
  pickupInstructions: string
  pickupContactPerson: string
  deliveryInstructions: string
  deliveryName: string
  deliveryAddress: string
  deliveryZipArea: string
  deliveryZipCode: string
  deliveryAddressLat: number
  deliveryAddressLng: number
  deliveryCountryCode: string
  deliveryPhone: string
  deliveryEmail?: string | null
  deliverySecondPhone?: string | null
  deliveryContactPerson: string
  orderNote: string | undefined
  customerId: string
  subcustomer: string
  customerRef: string
  customerInfo: string
  serviceId: string
  pickupTimeEarliest: string
  pickupDate: string
  pickupTimeLatest: string
  deliveryTimeEarliest: string
  deliveryDate?: string
  deliveryTimeLatest: string
  deliveryTimeWindowId: DeliveryTimeWindowIdType
  manualInvoiceInfo: string
  preplannedUnitId: string | null
  preplannedTemplateSlotId: string
  selectedDepartmentId: string | null
  invoiceInfo: string
  recipientRef: string
  measurements: List<IMeasurement>
  packageIds?: List<string>
  additionalServices: List<ValueAddedService>
  calculatedArticlePrices?: List<CorrectedArticlePrice>
  correctedArticlePrices?: List<CorrectedArticlePrice>
  adrGoods?: List<DangerousGoods>
  groupOrder?: number
  groupId?: string
  groupName?: string
  paidTogether?: boolean
  groupRecipients?: List<ImmutableMap<GroupRecipientProps>>
  groupOrders?: boolean
  fixedOrder?: boolean
  vehicleType?: string
  emailId?: string
  createdEvent?: boolean | false
  collectedEvent?: boolean | false
  deliveredEvent?: boolean | false
  customerContact?: string
  groupPickups?: List<ImmutableMap<GroupRecipientProps>>
  allowIncompletePrice?: boolean
  hasZeroPriceApproval?: boolean
  needsZeroPriceApproval?: boolean
  invoiceSplit?: string
  standaloneInvoice?: boolean
}

export interface IOrderForm extends ImmutableMap<IOrderFormProps> {}

export interface MeasurementProps {
  packageId?: string
  weight: string
  height: string
  length: string
  width: string
  presetDimension: string | null
  specificationDescription: string
  volume: string
}

export interface IMeasurement extends ImmutableMap<MeasurementProps> {}

export interface VasProps {
  vasCode: string
  quantity: string
  pureVas: boolean
  xValue?: string
  yValue?: string
  managed?: string
}

export interface ValueAddedService extends ImmutableMap<VasProps> {}

export interface CorrectedArticlePriceProps {
  articleCode: string
  customerPrice?: number
  resourcePrice?: number
  transportLegIndex?: number
}

export interface CorrectedArticlePrice extends ImmutableMap<CorrectedArticlePriceProps> {}

export interface ADRGoods {
  unNumber: string
  hazardous: string
  group: string
  netWeight: string
  name: string
  restrictions: string
  points: number
}

export interface ADRGoodsMap extends ImmutableMap<ADRGoods> {}

export const newVAS = (vasCode: string, quantity: string = '1', pureVas: boolean = false) =>
  ImmutableMapFactory<VasProps>({ vasCode, quantity, pureVas }) as ValueAddedService

export const emptyMeasurements = () =>
  ImmutableMapFactory<MeasurementProps>({
    packageId: '',
    weight: '0',
    height: '0',
    length: '0',
    width: '0',
    presetDimension: null,
    specificationDescription: '',
    volume: '0'
  }) as IMeasurement
